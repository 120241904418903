.footer {
  &__container {
    background-color: $white;
    text-align: center;
    width: 100%;
    @include paragraph;
  }

  &__link {
    @include paragraph;
    color: $dark-gray;
  }

  &__separator {
    margin: 0 12px;
  }
}
