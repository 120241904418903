.ellipsis-sl {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.ellipsis-2l {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.ellipsis-3l {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.mobile {
  display: none !important;
  @include respond(phone) {
    display: block !important;
  }
}

.desktop {
  display: flex !important;
  @include respond(phone) {
    display: none !important;
  }
}

.row-center {
  display: flex;
  align-items: center;
}

.col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mt4px {
  margin-top: 4px;
}

.mr10px {
  margin-right: 10px;
}

.h-45px {
  height: 45px;
}

.h-58px {
  height: 58px;
}

.w-190px {
  width: 190px;
}

.w-125px {
  width: 125px;
}

.color-orange {
  color: $orange;
}

.mt-70px {
  margin-top: 70px;
}

.invisible {
  visibility: hidden;
}

.hidden {
  display: none;
}
