.root {
  &__container {
    position: relative;
    margin: 0 auto;
    max-width: 1920px;
    min-height: 100vh;
    background: $background;

    @include respond(phone) {
      padding-left: 0;
    }

    &--padding {
      padding-bottom: 50px;
    }
  }

  &__content {
    display: flex;
    @include respond(phone) {
      margin-left: 0;
      padding: 20px 20px;
    }
  }

  &__section {
    width: calc(100% - 250px);
    display: flex;
    flex-direction: column;
    padding: 0 30px 50px 60px;
    background: $background;
    @include respond(phone) {
      width: 100%;
      padding: 0;
    }
  }
}
