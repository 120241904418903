.article {
  &__container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    @include respond(phone) {
      margin-top: 0;
    }
  }

  &__go-back {
    all: unset;
    display: flex;
    align-items: center;
    width: 150px;
    margin-bottom: 20px;
    font-size: 16px;
    i {
      margin-right: 10px;
    }
  }

  &__inner {
    display: flex;
    gap: 20px;
    @include respond(tablet) {
      flex-direction: column;
    }
  }

  &__date,
  &__body,
  &__author {
    font-size: 16px;
    color: $dark-gray;
  }

  &__main {
    width: 70%;
    display: flex;
    flex-direction: column;
    padding: 40px 60px;
    height: fit-content;
    @include respond(tablet) {
      width: 100%;
      padding: 20px;
    }

    p,
    table {
      font-size: 16px;
    }
  }

  &__title {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &__img {
    margin-bottom: 10px;
    width: 100%;
    border-radius: 4px;
  }

  &__body {
    overflow-wrap: break-word;
    margin-bottom: 20px;
  }

  &__author {
    padding-top: 20px;
    margin: 0;
  }

  &__card {
    width: 100%;
    height: fit-content;
    position: relative;

    @include respond(tablet) {
      margin: 0;
    }

    &:hover {
      i {
        color: $disabled;
      }
    }
  }
}
