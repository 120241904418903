.news {
  &__container {
    display: flex;
    width: 100%;
  }

  &__row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__skeletons {
    display: flex;
    gap: 20px;
  }
}
