.input {
  &__container {
    height: 40px;
    @include paragraph;
    font-weight: 400;
  }
  &__error,
  &__error--photo {
    font-size: 12px;
    font-weight: bolder;
    color: $error;

    &--photo {
      position: absolute;
      top: 40px;
    }
  }
}
