.details {
  &__container {
    display: flex;
    flex-direction: column;
  }

  &__title {
    margin: 0 auto;
    @include subheading;
    text-align: center;
  }

  &__comment {
    font-size: 16px;
    margin: 0 auto 30px;
    max-width: 700px;
    text-align: center;
  }

  &__subtitle {
    font-size: 22px;
    margin: 20px auto;
    text-align: center;
  }

  &__specialties,
  &__experience {
    columns: 3;

    @include respond(tablet) {
      columns: 2;
    }
    @include respond(phone) {
      columns: 1;
    }
  }

  &__specialties {
    .capabilities__block {
      break-inside: avoid;
    }
  }

  &__category {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2px;
  }

  &__certifications {
    columns: 5;
    margin-bottom: 45px;
    @include respond(tablet) {
      columns: 4;
    }
    @include respond(phone) {
      columns: 2;
      margin-bottom: 15px;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__actions {
    display: flex;
    @include respond(phone) {
      flex-direction: column;
      width: 100%;
    }
  }

  &__action {
    @include respond(phone) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  &__error {
    color: $error;
    font-size: 12px;
    font-weight: 600;
  }
}

.detail {
  &__container {
    margin: 30px 0 0;
    @include respond(phone) {
      margin: 0;
    }
  }
  &__widgets {
    display: flex;
    margin-top: 20px;
    @include respond(tablet) {
      flex-direction: column;
    }
  }
  &__user-info {
    display: flex;
    align-items: center;
    margin: 20px;
    gap: 20px;
    @include respond(phone) {
      flex-direction: column;
      padding: 0 20px;
    }
  }
  &__user-name {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    @include respond(phone) {
      align-items: center;
    }
  }
  &__tab {
    background: $blue;
    color: $white;
    width: 110px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    @include paragraph;
    @include btn-clickable;

    @media screen and (max-width: 615px) {
      width: 100%;
    }

    &-container {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
    }
  }
  &__img {
    width: 100px;
    height: 100px;
    min-width: 100px;
    border-radius: 50%;
    overflow: hidden;
    @include scale-transition;

    img {
      object-fit: cover;
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }
  }

  &__firm-name,
  &__name {
    @include paragraph;
    margin: 0;
  }

  &__firm-name {
    color: $black;
  }

  &__name {
    color: $blue;
  }

  &__icon {
    &--globe {
      font-size: 16px;
      color: $blue;
    }
    &--social {
      color: $blue;
      font-size: 22px;
      margin-right: 3px;
    }
    &--phone,
    &--printer,
    &--globe,
    &--mobile {
      margin-right: 6px;
    }

    &--user {
      font-size: 100px;
      margin: 20px 0;
    }
  }
  &__contact {
    color: $blue;
    @include paragraph;
    text-decoration: none;
    line-height: 21px;
  }
  &__office {
    @include paragraph;
    margin: 0;
    line-height: 1.2;
  }
  &__btn {
    width: fit-content;
    min-width: 112px;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    background: $blue;
    border-radius: 4px;
    color: $white;
    border: 1px solid $blue;
    font-size: 12px;
    font-weight: 400;
    @include paragraph;
    @include btn-clickable;

    @include respond(phone) {
      margin: 10px 0;
    }

    &--download {
      font-size: 16px;
    }
  }
  &__url {
    color: $blue;
    @include paragraph;
  }
  &__social {
    display: flex;
  }

  &__widget {
    padding: 20px;
    width: 100%;
    @include respond(tablet) {
      margin: 20px 0 0;
    }
    &-container {
      &--full {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      &--large {
        display: flex;
        flex-direction: column;
        width: 75%;
        @include respond(tablet) {
          width: 100%;
        }
      }
      &--small {
        display: flex;
        flex-direction: column;
        width: 25%;
        margin-left: 20px;
        gap: 20px;
        @include respond(tablet) {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
  &__downloads {
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
  }
  &__brochure-img {
    width: 100%;
    min-width: 30px;
    max-width: 30px;
  }

  &__description {
    font-size: 16px;
    margin: 0 0 10px;
  }

  &__title {
    @include subheading;
    margin-bottom: 20px;
  }

  &__umbrellas {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
