.checklist {
  &__container {
    width: 75%;

    @include respond(phone) {
      width: 100%;
    }
  }

  &__subtitle {
    @include subheading-small;
  }

  &__list,
  &__sublist {
    list-style: none;
    padding: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 6px;

    i {
      font-size: 12px;
      color: $success;
    }

    li {
      @include paragraph;
    }
  }

  &__sublist {
    margin-left: 30px;
  }

  &__btn {
    @include blue-btn;
    padding: 0 15px;

    @include respond(phone) {
      padding: 5px 15px;
      height: unset;
    }
  }
}
