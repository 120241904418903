.accordion {
  &__header {
    display: flex;
    align-items: center;
  }
  &__item {
    position: relative;
  }

  &__arrow {
    transform: translateX(20px);
    z-index: 100;
    transition: all 0.2s linear;

    &--up {
      transform: translateX(20px) rotate(180deg);
    }
  }

  &-button {
    padding-right: 40px;
  }

  &-body {
    @include respond(phone) {
      padding: 20px 0;
    }
  }
}
