.office {
  &__container {
    @include white-box;
    padding: 20px;
  }

  &__header,
  &__flag {
    display: flex;
    margin: 10px 0;
  }

  &__header {
    align-items: flex-start;
  }
  &__flag {
    align-items: center;
  }

  &__general {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
  }

  &__country {
    @include paragraph;
    margin: 0;
    font-weight: 700;
    line-height: 1;
  }

  &__data {
    padding-bottom: 10px;
  }

  &__head-office {
    font-size: 16px;
    font-weight: 700;
  }

  &__detail {
    @include paragraph;
    line-height: 1.2;
    margin: 0 0 4px;

    &--email {
      margin-left: 4px;
    }
  }

  &__btn {
    @include blue-btn;
    margin: 10px auto 0;
    height: 40px;
    width: 300px;
    max-width: 100%;

    &--disabled {
      @include disabled-btn;
      height: 40px;
    }
  }

  &__tip {
    color: $disabled-active;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 0;
  }

  &__phone {
    display: flex;
    gap: 10px;
  }

  &__input {
    &--code {
      width: 30%;
      min-width: 100px;
    }
    &--phone {
      width: 70%;
      min-width: 110px;
    }
  }
}
