.flash {
  background-color: $success;
  min-height: 50px;
  padding: 15px 44px 15px 20px;
  position: relative;
  &__message {
    color: $white;
    font-size: 16px;
  }

  &__cancel {
    color: $white;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    right: 16px;
    top: calc(50% - 12px);
  }

  &__error {
    background-color: $error;
  }
}
