#root {
  max-width: 1920px;
  margin: 0 auto;
}

::selection {
  background-color: $orange;
  color: $dark-gray;
}

a {
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  // this style removes the default style of inputs when autofill
  transition: background-color 600000s 0s, color 600000s 0s;
}

body {
  background: $white;
  font-family: 'Arial';
}

// overwrite the default styles of the sidebar
.app-sidebar {
  width: 250px;
  @media (max-width: 767.98px) {
    left: -250px;
  }
}

.app-sidebar .app-sidebar-content {
  width: 250px;
}
