.account {
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__warning {
    border-top: 1px solid $orange;
    border-bottom: 1px solid $orange;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 14px 0;
    margin-bottom: 20px;
  }

  &__info-container {
    display: flex;
    align-items: center;

    &--start {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;
    }
  }

  &__icon,
  &__info,
  &__important,
  &__required {
    @include paragraph;
  }

  &__important {
    font-weight: 600;
    margin-bottom: 6px;
  }

  &__important,
  &__info {
    text-align: center;
  }

  &__icon {
    color: $orange;
    margin-right: 6px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    label {
      @include paragraph;
      white-space: nowrap;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    column-gap: 30px;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }

    &--centered {
      justify-content: center;
      column-gap: 30px;
    }
  }

  &__salutation {
    width: 11%;
    @media screen and (max-width: 1200px) {
      height: 90px;
    }
  }

  &__names {
    @include respond(tablet) {
      height: 67px;
    }
  }

  &__password {
    margin-bottom: 8px;
  }

  &__phones {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  &__phone {
    display: flex;
    width: 100%;
  }

  &__names,
  &__password,
  &__email,
  &__reason {
    width: 39%;
  }

  &__surnames {
    width: 42%;
  }

  &__password-info {
    display: flex;
    width: 80%;
    margin: 10px 0 0;
    @include respond(tablet) {
      margin: 0;
    }
  }

  &__code {
    width: 33%;
    margin-right: 10px;
  }

  &__number {
    width: 67%;
  }

  &__country,
  &__city,
  &__firm {
    width: 30%;
    @include respond(tablet) {
      margin-bottom: 20px;
    }
  }

  &__location-container {
    justify-content: space-between;
  }

  &__reason-container {
    width: 100%;
    justify-content: flex-end;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__salutation,
  &__names,
  &__password,
  &__email,
  &__reason,
  &__surnames,
  &__password-info,
  &__number,
  &__country,
  &__city,
  &__firm,
  &__phones {
    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }

  &__names,
  &__password,
  &__phones,
  &__country,
  &__city,
  &__firm,
  &__reason {
    @media screen and (max-width: 1200px) {
      margin-bottom: 23px;
    }
  }
}
