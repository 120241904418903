// COLORS
$black: #000000;
$bootstrap-blue: #deebff;
$light-blue: #5db0ff;
$blue: #0075c9;
$blue-active: #1d4b7f;
$white: #ffffff;
$dark-gray: #2d353c;
$orange: #f5a800;
$orange-light: #f7a909;
$orange-dark: #f15a24;
$placeholder: #dedede;
$gray: #495057;
$gray-header: #666666;
$disabled: #999999;
$disabled-active: #6c757d;
$disabled-card: #555555;
$light-gray: #dee2e6;
$lighter-gray: #999999c4;
$background: #f1f2f2;
$error: #e1251b;
$success: #008000;
// ELEMENTS
$input-border: #cccccc;
$separator-light: 1px solid #ced4da;
$widget-border: 1px solid #dde1e5;
$widget-padding: 0.61875rem 0.9375rem;
$shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
