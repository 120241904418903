.pager {
  &__container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__inner {
    display: flex;
    padding: 10px;
    flex-wrap: wrap;
  }

  &__selector {
    font-size: 16px;
    color: $blue;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
  }

  &__active {
    color: $white;
    background-color: $blue;
    border-radius: 50%;
    cursor: initial;
  }

  &__disabled {
    cursor: initial;
  }

  &__ellipsis {
    color: var(--color-brand-blue);
    font-size: 13px;
    margin-right: 10px;
  }
}
