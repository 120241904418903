.staff {
  &__container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__column {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    @include respond(phone) {
      margin-right: 16px;
    }
  }

  &__options {
    position: relative;
    @include paragraph;
    color: $disabled-active;
    cursor: pointer;

    @include respond(phone) {
      position: absolute;
      top: 4px;
      right: 4px;
    }
  }

  &__card {
    display: flex;
    background-color: $white;
    padding: 16px 20px;
    border-radius: 4px;
    width: calc(50% - 10px);
    position: relative;
    @include shadow-on-hover;

    @media only screen and (max-width: 1200px) {
      width: 100%;
    }

    &--deactivated {
      background: $disabled-card;
      p,
      li,
      .staff__options {
        color: $white;
      }
    }
  }

  &__text {
    &--main {
      color: $dark-gray;
      font-size: 16px;
      margin: 0 0 4px;
      font-weight: 600;
      line-height: 1;
    }

    &--secondary {
      color: $dark-gray;
      @include paragraph;
      margin-bottom: 4px;
      font-weight: 400;
      line-height: 1;
    }
  }

  &__header {
    margin: 20px 0 0;
    @include paragraph;
  }

  &__list {
    background: $white;
    width: 75%;

    @include respond(tablet) {
      width: 100%;
    }
  }

  &__corner {
    overflow: hidden;
    border-radius: 0 4px 0 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background: $error;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
  }
}
