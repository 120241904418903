.forgot {
  &__container {
    margin: 50px auto 0;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 400px;
    @include white-box;
    padding: 30px 50px;
    left: 120px;

    @include respond(phone) {
      max-width: 400px;
      width: calc(100% - 40px);
      left: 0;
      padding: 30px 30px;
    }

    &--middle {
      left: 0;
    }
  }
  &__title {
    @include subheading;
    margin-bottom: 20px;
    text-align: center;
  }
  &__subtitle {
    @include paragraph;
    margin: 0 auto 20px;
    text-align: center;
  }

  &__btn {
    @include blue-btn;
    width: 100%;
    margin: 24px auto 10px;

    &--disabled {
      @include disabled-btn;
    }
  }

  &__link {
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    margin: 10px auto 0;
    text-align: center;
    color: $blue;
    width: 100%;

    &-container {
      display: flex;
      justify-content: center;
    }
  }

  &__label {
    width: 100%;
    text-align: start;
    display: block;
  }
  &__input,
  &__label {
    @include paragraph;
    font-weight: 400;
  }

  &__input {
    height: 40px;
    &--error {
      border: 1px solid $error;
    }
  }

  &__error {
    display: flex;
    color: $error;
    font-size: 12px;
    font-weight: 600;
  }
}

.reset {
  margin-top: 30px;

  &__error {
    position: relative;
    top: 10px;
    font-size: 16px;
    font-weight: bolder;
    color: $error;
  }
}
