.sidebar {
  &__container {
    min-height: 100%;
    overflow: scroll;
  }

  &__section {
    font-size: 16px;
    cursor: pointer;
    color: $dark-gray;
    transition: all 0.1s;

    &:hover {
      color: $orange;
    }

    &--active {
      font-size: 16px;
      cursor: pointer;
      color: $dark-gray;
      border-left: 6px solid $orange;
      border-radius: 0 4px 4px 0;
      width: 100% !important;
      transition: all 0.2s ease-in-out;
      background: $light-gray;
      &:hover,
      &:active,
      &:focus {
        color: $dark-gray;
      }
    }
  }

  &__page {
    font-size: 15px;
    cursor: pointer;
    color: $dark-gray;
    background: transparent;

    &:hover,
    &:active,
    &:focus,
    &--active {
      color: $blue;
      transition: all 0.1s;
    }
  }

  &__icon {
    @include paragraph;
    margin: 0 10px;
    width: 14px;
  }
  &__profile {
    min-height: 120px;
  }

  &__link {
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
      color: #2d353c !important;
    }
  }
}

#sidebar {
  background: $background;
}
