.directory {
  &__title {
    @include main-heading;
  }
  &__tab {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $blue;
    @include btn-clickable;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    border-radius: 4px;
    justify-content: space-between;
    gap: 20px;
  }

  &__list-container {
    display: flex;
    @include respond(phone) {
      flex-direction: column-reverse;
    }
  }

  &__key-contacts {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__card {
    background-color: $white;
    border-radius: 4px;
    width: calc(50% - 10px);
    //min-width: fit-content;
    @include scale-transition-medium;
    @include shadow-on-hover;

    @media only screen and (max-width: 1200px) {
      width: 100%;
    }
  }

  &__overview {
    display: flex;
    flex-direction: column;
    @include white-box;
    padding: 0 20px 20px;
  }

  &__help {
    font-weight: bold;
    margin: 20px 20px 0;
  }
}
