.page {
  &__tabs {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding-top: 30px;

    @include respond(phone) {
      padding-top: 0;
    }

    &--responsive {
      @media screen and (max-width: 480px) {
        display: none;
      }
    }
  }

  &__tab {
    @include tab;
    @include btn-clickable;
    line-height: 1.2;
    min-height: 24px;

    &:hover,
    &:focus,
    &:active {
      color: $white;
      cursor: pointer;
    }

    &--active {
      background: $blue-active;
    }

    @media screen and (max-width: 480px) {
      width: calc(50% - 13px);
      padding: 4px;
    }
  }

  &__sub-tabs {
    width: 100%;
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  &__sub-tab {
    all: unset;
    color: $black;
    font-size: 15px;
    line-height: 1.2;
    min-height: 24px;
    transition: font-weight 0.2s ease-in-out;
    text-align: center;
    width: 150px;
    min-width: 150px;
    border-bottom: 5px solid $blue;
    cursor: pointer;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 480px) {
      @include tab;
      @include btn-clickable;
      background-color: $blue;

      width: calc(50% - 13px);
      min-width: unset;
      margin: 0;
      padding: 4px 10px;
    }
    &:hover,
    &:focus,
    &:active,
    &--active {
      font-weight: 600;
      color: $blue-active;
      border-bottom: 5px solid $blue-active;
      @media screen and (max-width: 480px) {
        font-weight: 400;
        border-bottom: none;
        background-color: $blue-active;
        color: $white;
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__body {
    display: flex;
    gap: 20px;
    margin-top: 10px;

    @include respond(tablet) {
      flex-direction: column;
    }
  }

  &__content {
    @include white-box;
    padding: 20px;
    width: 70%;
    @include respond(tablet) {
      width: 100%;
    }
  }

  &__tiles {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    width: 30%;
    @include respond(tablet) {
      flex-direction: row;
      width: 100%;
    }
    @include respond(phone) {
      justify-content: center;
    }
  }
}
