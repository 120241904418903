.profile {
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__required {
    @include paragraph;
    margin-bottom: 12px;
  }
  &__title {
    @include subheading;
    margin-bottom: 12px;
    @include respond(phone) {
      text-align: center;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__btn {
    width: 190px;
    @include blue-btn;
    @include paragraph;
    height: 40px;
    justify-content: center;
    position: relative;

    label {
      cursor: pointer;
    }

    &--cv {
      position: relative;
      top: -40px;
      left: 130px;
      max-width: 250px;
      @include respond(phone) {
        top: 0;
        left: 0;
      }
    }

    &--brochure {
      width: 200px;
    }
  }

  &__file {
    @include paragraph;
    display: flex;
    margin-top: 10px;

    &-name {
      white-space: nowrap;
      width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__icon {
    font-size: 18px;

    &--linkedIn {
      color: $disabled;
      font-size: 20px;
    }
  }

  &__about {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    flex-wrap: wrap;
    @include respond(phone) {
      justify-content: center;
    }
  }

  &__files {
    display: flex;
    p.show-error {
      visibility: hidden;
    }
  }

  &__photo {
    display: flex;
    @include respond(phone) {
      flex-direction: column;
    }
  }

  &__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;

    @include respond(phone) {
      margin: 0 auto 20px;
    }

    &-container {
      margin-right: 30px;
      position: relative;
      width: 100px;
      height: 100px;
      background: $placeholder;
      overflow: hidden;

      @include respond(phone) {
        margin-right: 0;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    @include respond(phone) {
      margin-bottom: 20px;
    }
  }

  &__linkedIn {
    width: 300px;

    @include respond(phone) {
      width: 100%;
    }

    label {
      display: flex;
      gap: 6px;
    }
  }

  &__label {
    &--bio {
      @include paragraph;
      font-weight: 600;
    }
  }

  &__textarea {
    height: 140px;
    font-weight: 400;
    @include paragraph;
  }

  &__subtitle {
    margin: 46px 0 30px;
    @include subheading-small;

    &--light {
      font-weight: 400;
    }
  }

  &__warning {
    display: flex;
    @include paragraph;
    font-weight: 600;
    margin-bottom: 16px;
  }

  &__tooltip {
    font-size: 18px;
    color: $orange;
    margin-right: 6px;
    &--small {
      font-size: 12px;
      margin-bottom: 8px;
    }
  }

  &__roles {
    columns: 3;

    @include respond(tablet) {
      columns: 2;
    }
    @include respond(phone) {
      columns: 1;
    }
  }

  &__role {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &--disabled {
      color: $disabled;
    }
  }

  &__footer {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    @include respond(phone) {
      margin-top: 10px;
    }
  }

  &__btns {
    display: flex;
    @include respond(phone) {
      width: 100%;
      flex-direction: column;
    }
  }

  &__error {
    color: $error;
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;
  }

  &__tip {
    position: relative;
    top: -18px;
  }
}

label.checkbox {
  display: flex;
  align-items: flex-start;
  @include paragraph;
  gap: 5px;

  input {
    position: relative;
    top: 6px;
  }
  span {
    label {
      margin: 0 6px 0 0;
      @include paragraph;
    }
  }
}
