.user {
  &__container {
    margin-top: 20px;
    background: $white;
    padding: 30px;
  }

  &__flash {
    margin-bottom: 20px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    margin-bottom: 20px;
    gap: 20px;

    &--left {
      display: flex;
      flex-direction: column;
    }

    &--right,
    &--bottom {
      display: flex;
      justify-content: flex-end;
      gap: 15px;
    }

    &--bottom {
      width: 100%;
    }
  }

  &__comment {
    margin: 0;
    @include paragraph;

    &--currently {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    &--star {
      margin: 20px 0 10px;
    }
  }

  &__btn {
    @include blue-btn;

    &--cv {
      width: 190px;
      position: relative;
      top: -40px;
      left: 130px;
      @include paragraph;

      @include respond(phone) {
        position: initial;
        margin: 0;
      }
    }
  }

  &__btn-secondary {
    @include disabled-btn;

    &--not-allowed {
      cursor: not-allowed;
    }
  }

  &__btn,
  &__btn-secondary {
    height: 40px;
    padding: 0 15px;
  }

  &__data {
    display: flex;
    flex-direction: column;
    margin: 0 0 30px;
  }

  &__help {
    margin: 15px 0 20px;
    @include respond(tablet) {
      margin-bottom: 15px !important;
    }
  }

  &__title {
    @include subheading;

    &--personal {
      margin: 6px 0 0;
    }

    &-container {
      display: flex;
      gap: 10px;
      margin: 20px 0;
    }
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 400;
  }

  &__inputs {
    display: flex;
    gap: 30px;
    @include respond(phone) {
      flex-direction: column;
    }

    label {
      @include paragraph;
      margin-bottom: 6px;
    }

    .input__error {
      top: 68px;
    }

    &--profile {
      @include respond(tablet) {
        flex-direction: column;
      }
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    width: 50%;
    @include respond(phone) {
      width: 100%;
    }

    &--profile {
      @include respond(tablet) {
        width: 100%;
      }
    }
  }

  &__input {
    margin-bottom: 20px;

    &--firm {
      margin-top: 47px;
    }

    &--photo {
      min-width: 320px;
      display: flex;

      @include respond(phone) {
        min-width: unset;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
      }

      &-action {
        display: flex;
        flex-direction: column;
        position: relative;
      }
    }

    &--cv {
      height: 20px;

      @include respond(phone) {
        height: auto;
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
      }
      &-action {
        display: flex;
        flex-direction: column;
        position: relative;
        top: -40px;
        left: 130px;

        @include respond(phone) {
          top: 0;
          left: 0;
        }
      }
    }
    &--file {
      @include paragraph;
      display: flex;
      margin-top: 4px;
      position: relative;
      @include respond(phone) {
        position: inherit;
        margin-top: 8px;
      }
    }

    &--bio {
      label {
        @include paragraph;
        margin-bottom: 6px;
      }
      textarea {
        height: 100px;
        @include paragraph;
        font-weight: 400;
      }
    }

    &--code {
      width: 30%;
      margin-bottom: 14px;
    }
    &--phone {
      width: 70%;
      margin-bottom: 14px;
    }
  }

  &__checklist {
    columns: 3;
    @media only screen and (max-width: 1200px) {
      columns: 2;
    }
    @include respond(phone) {
      columns: 1;
    }

    &--condensed {
      columns: 4;
      @include respond(tablet) {
        columns: 3;
      }
      @include respond(phone) {
        columns: 2;
      }
    }

    .user__items {
      break-inside: avoid;
    }
  }

  &__languages {
    margin: 0 14% 0 24px;
    position: relative;

    label {
      @include paragraph;
      margin-bottom: 6px;
    }

    .input__error {
      top: 68px;
    }
  }

  &__section-subtitle {
    @include subheading-small;
    margin: 30px 0 20px;
  }

  &__category {
    @include category-title;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    gap: 6px;

    input {
      position: relative;
      top: 5px;
    }

    &--disabled {
      color: $disabled;
    }

    &--red {
      color: $error;
    }
  }

  &__multiple-field {
    display: flex;
    flex-direction: column;
  }

  &__phones {
    display: flex;
    gap: 10px;
  }

  &__firm {
    &-name {
      @include subheading-small;
      margin: 0 0 10px 6px;
    }

    &-input {
      margin-top: 37px;
    }
  }

  &__label {
    display: flex;
    flex-direction: column;
    break-inside: avoid;
    
    &--care-of {
      margin-left: 6px;
    }

    &--certification {
      color: $gray-header;
      font-size: 14px;
      font-style: italic;
    }
  }
}
