.skeleton {
  &__article,
  &__banner,
  &__widget,
  &__firm-card,
  &__member-card,
  &__resource,
  &__member-detail {
    width: 100%;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

    &.is-loading {
      .image,
      h2,
      h3,
      p,
      span {
        background: #eee;
        background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
      }

      .image {
        height: 200px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .image {
      img {
        max-width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
  }
  &__article {
    .content {
      padding: 20px 30px;
    }
  }

  &__article.is-loading {
    h2 {
      height: 30px;
    }

    p {
      height: 70px;
    }
  }

  &__banner {
    height: 107px;

    .content {
      padding: 15px;
    }

    h2 {
      height: 20px;
    }

    p {
      height: 50px;
      margin-bottom: 0;
    }
  }

  &__widget {
    .content {
      padding: 15px;
    }
    p {
      height: 50px;
    }
  }

  &__firm-card {
    height: 82px;
  }

  &__member-card {
    height: 100px;
    display: flex;
    gap: 20px;
    height: 150px;
    padding: 16px;
  }

  &__firm-card,
  &__member-card {
    &.is-loading {
      h2 {
        height: 20px;
      }

      p {
        height: 10px;
      }
      span {
        height: 30px;
        width: 124px;
      }
    }
  }

  &__resource {
    &.is-loading {
      h2 {
        height: 20px;
        width: 100%;
      }

      p {
        height: 10px;
      }
      span {
        height: 30px;
        width: 124px;
      }
    }
  }

  &__actions {
    display: flex;
    gap: 18px;
    align-items: flex-end;
  }
  &__icon {
    height: 48px;
    width: 36px;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 2px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  &__columns {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px 0;
  }
  &__column {
    width: 30%;
    height: 50px;

    &--50 {
      width: 50%;
    }
  }

  &__title--centered {
    margin-top: 10px;
  }

  &__image {
    height: 60px;
    width: 60px;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 30px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }

  &__member-detail {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 75%;

    @include respond(phone) {
      width: 100%;
    }

    .content {
      @include respond(phone) {
        margin: 0 auto;
      }
    }
    .skeleton__image {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      @include respond(phone) {
        margin: 0 auto;
      }
    }

    h2 {
      margin: 30px 0 10px 30px;
      height: 20px;
      width: 200px;
      @include respond(phone) {
        margin: 30px auto 10px;
      }
    }

    h3 {
      margin: 10px 0;
      height: 16px;
      width: 200px;
      @include respond(phone) {
        margin: 10px auto;
      }
    }

    p {
      height: 16px;
      width: 200px;
      margin-left: 30px;
      @include respond(phone) {
        margin: 10px auto;
      }
    }
  }

  &__section {
    width: 100%;
    border-top: 1px solid #ced4da;
  }
}
