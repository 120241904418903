.banner {
  &__container {
    display: flex;
    width: calc(25% - 15px);
    overflow: hidden;
    position: relative;
    @include white-box;

    &:hover {
      box-shadow: $shadow;
    }
    @include scale-transition;

    @include respond(tablet) {
      width: calc(49% - 5px);
    }
    @include respond(phone) {
      width: 100%;
    }
  }

  &__background {
    position: absolute;
    z-index: 1;
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  &__card {
    width: 100%;
    text-decoration: none;
    padding: 15px;
    z-index: 2;
  }

  &__icon {
    width: 30px;
    height: 30px;
    background-color: #f5a800;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }

  &__paragraph {
    @include paragraph;
    margin: 0;
    color: $dark-gray;
  }

  &__title {
    font-size: 20px;
    color: $dark-gray;
    margin-bottom: 0;

    &--white {
      color: $white;
      text-shadow: 1px 1px $dark-gray;
    }
  }
}
