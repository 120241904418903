.row {
  &__container {
    width: 100%;
    margin-bottom: 40px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__skeletons {
    display: flex;
    gap: 20px;
    width: 100%;

    @include respond(phone) {
      flex-wrap: wrap;
    }
  }
}
