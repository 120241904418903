.terms {
  &__container {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  &__subtitle {
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 16px;
  }
  &__paragraph,
  &__item,
  &__comment {
    @include paragraph;
  }
  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__comment {
    margin-bottom: 10px;
  }
  &__accept {
    width: 400px;
    max-width: 100%;
    @include blue-btn;
    margin-bottom: 20px;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 10px;
    height: unset;
  }
  &__cancel {
    width: 400px;
    max-width: 100%;
    @include disabled-btn;
  }
}
