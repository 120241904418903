.layout {
  &__container {
    margin: 0;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    @include respond(phone) {
      padding: 0;
    }
  }

  &__tab {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include paragraph;
    padding: 10px;
    width: 150px;
  }

  &__title {
    @include main-heading;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__article {
    width: calc(33% - 20px);
    min-width: 250px;

    @media screen and (min-width: 1389px) {
      width: calc(25% - 20px);
    }
    @include respond(tablet) {
      width: calc(50% - 20px);
    }
    @include respond(phone) {
      width: 100%;
    }
  }

  &__comment {
    font-size: 16px;
    color: $disabled-active;
    margin-bottom: 20px;
  }
}
