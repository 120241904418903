.event {
  width: calc(25% - 20px);
  min-width: 250px;
  color: $dark-gray !important;
  @include shadow-on-hover;

  @include respond(tablet) {
    width: calc(50% - 20px);
  }
  @include respond(phone) {
    width: 100%;
  }

  &__container {
    border-radius: 4px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-bottom: 10px;
    height: 100%;
    position: relative;
  }

  &__img {
    width: 100%;
    @include scale-transition;
    transform-origin: bottom center;
  }

  &__title {
    @include event-title;

    &:hover,
    &:foucs,
    &:active {
      color: $dark-gray;
    }
  }

  &__data {
    margin: 12px 0 0;
    font-size: 16px;
    padding: 0 10px;
    color: $dark-gray;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__icon {
    color: $orange;
    font-size: 16px;

    &--computer,
    &--location {
      position: relative;
    }
    &--computer {
      left: -2px;
    }

    &--location {
      left: 1px;
    }
  }

  &__date,
  &__address {
    margin: 0;
    line-height: 1.4;
  }
}
