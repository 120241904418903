.wip {
  &__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $background;
    color: $black;
    gap: 10px;
  }

  &__text {
    margin: 0 30px;
    font-size: 30px;
    text-align: center;
    line-height: 45px;
  }

  &__icon {
    font-size: 60px;
    color: $disabled;
    margin-bottom: 20px;
  }

  &__btn {
    @include blue-btn;
    padding: 0 20px;
    margin-top: 40px;
  }
}
