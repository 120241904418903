$search-width: 550px;

.search {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
    padding: 10px 20px;

    @include respond(tablet) {
      background: $white;
      width: 100vw;
      position: absolute;
      top: 50px;
      left: 0px;
      box-shadow: 0 0.7rem 0.5rem rgba(0, 0, 0, 0.1);
    }
  }

  &__elements {
    display: flex;
    align-items: center;
    gap: 12px;
    position: relative;

    @include respond(tablet) {
      gap: 6px;
    }

    @include respond(phone) {
      flex-direction: column;
      gap: 6px;
    }
  }

  &__input-container {
    width: 100%;
    position: relative;

    @include respond(phone) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &__input {
    font-size: 16px;
    font-weight: 400;
    width: $search-width;
    max-width: 100%;
    height: 40px;
    padding: 6px 12px;
    transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);

    @include respond(tablet) {
      width: 100%;
    }

    &--disabled {
      font-style: italic;
      background-color: $white !important;
    }
  }

  &__dropdown {
    background: $white;
    position: absolute;
    top: 40px;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 6px 0 12px;
    width: 100%;
    max-height: 80vh;
    overflow-y: scroll;
    width: $search-width;

    @include respond(phone) {
      top: 77px;
      width: 100%;
    }

    .dropdown-item {
      white-space: break-spaces;
      cursor: pointer;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-right: 12px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;

    @include respond(phone) {
      position: relative;
      margin: 0 auto;
    }
  }

  &__button {
    cursor: pointer;
    padding: 3px 6px;
    transition: all 0.2s;
    white-space: nowrap;
    font-size: 12px;
    font-weight: bold;
    background: $disabled;
    border-radius: 4px;
    color: $white;

    &--active {
      background: $orange;
    }
  }

  &__label {
    white-space: nowrap;
  }

  &__img {
    object-fit: cover;
    border-radius: 50%;
    height: 30px;
    width: 30px;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 6px 12px;
    font-size: 16px;
    color: $black;

    &:hover {
      background: #deebff;
    }

    .flag {
      width: 25px;
    }
  }

  &__title {
    margin-right: auto;
  }

  &__link {
    &--see-more {
      color: $black;
      font-weight: 600;
      justify-content: center;
      margin: 0;
    }
  }

  &__tag {
    background: #999999c4;
    border-radius: 4px;
    font-size: 12px;
    padding: 0 4px;
    color: $white;
  }
}
