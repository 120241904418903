.pending {
  &__header {
    display: flex;
    padding: 10px 0 10px 92px;
    @include respond(phone) {
      display: none;
    }
  }

  &__card {
    display: flex;
    align-items: center;
    padding: 20px 0;
    flex-grow: 1;
    width: 100%;
    @include white-box;
    min-height: 120px;
    transition: none;
    border-bottom: $separator-light;
    
    @include respond(phone) {
      flex-wrap: wrap;
      flex-direction: column;
      position: relative;
    }

    &:last-child {
      border: none;
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: $disabled-active;
    width: 20%;
  }

  &__feedback {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    text-transform: capitalize;
    padding: 10px 0 20px;
    color: $gray;
  }

  &__img {
    width: 70px;
    min-width: 70px;
    height: 70px;
    margin-right: 15px;
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    flex-grow: 1;
    @include respond(phone) {
      flex-direction: column;
    }
  }

  &__name {
    color: $dark-gray;
    font-size: 16px;
    margin: 0 0 4px;
    font-weight: 600;
    line-height: 1;
    width: 20%;

    @include respond(phone) {
      margin-top: 20px;
    }
  }

  &__data {
    @include paragraph;
    width: 20%;
    margin-right: 10px;
  }

  &__name,
  &__data {
    @include respond(phone) {
      width: 100%;
      text-align: center;
    }
  }
  &__actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @include respond(phone) {
      margin-top: 10px;
      width: 200px;
    }
  }

  &__btn {
    &--approve {
      @include blue-btn;
    }
    &--reject {
      @include disabled-btn;
      &:hover {
        background: $error;
      }
    }

    &--approve,
    &--reject {
      padding: 0 10px;
      font-size: 13px;
      height: 40px;
    }
  }

  &__checkbox {
    font-size: 18px;
    cursor: pointer;
    margin-right: 20px;
    @include respond(phone) {
      margin: 20px auto 0;
    }
  }

  &__options {
    margin-left: 20px;
  }
}
