.markdown {
  a {
    font-weight: 600;
    color: $blue;

    &:hover {
      color: $blue-active;
    }
  }

  h1 {
    @include main-heading;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;

    img {
      max-width: 100% !important;
      display: inline-block;
      margin: 10px auto;
      border-radius: 4px;
    }
  }

  p:has(img),
  a:has(img) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .images {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }

  table {
    width: 100%;
    border: 1px solid #d1d9df;
    border-radius: 4px;
    thead {
      background: #e8ebee;
    }
    td {
      border: 1px solid #d1d9df;
    }
    th,
    td {
      padding-left: 6px;
    }
  }
}
