// ICONS CONFIG
@font-face {
  font-family: 'icons';
  src: url('../../assets/icons/icomoon.eot?n624wm');
  src: url('../../assets/icons/icomoon.eot?n624wm#iefix') format('embedded-opentype'),
    url('../../assets/icons/icomoon.ttf?n624wm') format('truetype'),
    url('../../assets/icons/icomoon.woff?n624wm') format('woff'),
    url('../../assets/icons/icomoon.svg?n624wm#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// PASTE YOUR NEW ICON UNDER THIS LINE*

.icon-circle-star:before {
  content: '\e923';
  color: #999;
}

// BLACK ICONS
.icon-comment-solid:before {
  content: '\e925';
}
.icon-thumbtack:before {
  content: '\e924';
}
.icon-arrow-right:before {
  content: '\e9bf';
}
.icon-arrow-left:before {
  content: '\e9be';
}
.icon-file-word:before {
  content: '\e912';
}
.icon-file-powerpoint:before {
  content: '\e913';
}
.icon-file-excel:before {
  content: '\e914';
}
.icon-external-link:before {
  content: '\e915';
}
.icon-file-video:before {
  content: '\e916';
}
.icon-file-pdf:before {
  content: '\e917';
}
.icon-file-document:before {
  content: '\e922';
}
.icon-file-zipper:before {
  content: '\e918';
}
.icon-clipboard-regular:before {
  content: '\e919';
}
.icon-globe-americas-solid:before {
  content: '\e91a';
}
.icon-city-solid:before {
  content: '\e91b';
}
.icon-building-solid:before {
  content: '\e91c';
}
.icon-compass-solid:before {
  content: '\e91d';
}
.icon-house-solid:before {
  content: '\e91e';
}
.icon-envelope-regular:before {
  content: '\e91f';
}
.icon-mobile-alt-solid:before {
  content: '\e920';
}
.icon-phone-alt-solid:before {
  content: '\e921';
}
.icon-comment:before {
  content: '\e911';
}
.icon-plus:before {
  content: '\e910';
}
.icon-link:before {
  content: '\e90f';
}
.icon-search:before {
  content: '\e90e';
}
.icon-suitcase:before {
  content: '\e90c';
}
.icon-user:before {
  content: '\e90d';
}
.icon-arrowDown:before {
  content: '\e90b';
}
.icon-globe:before {
  content: '\e900';
}
.icon-landmark:before {
  content: '\e901';
}
.icon-seedling:before {
  content: '\e902';
}
.icon-desktop:before {
  content: '\e903';
}
.icon-certificate:before {
  content: '\e904';
}
.icon-users:before {
  content: '\e905';
}
.icon-newspaper:before {
  content: '\e906';
}
.icon-chalkboard:before {
  content: '\e907';
}
.icon-calendar:before {
  content: '\e908';
}
.icon-addressBook:before {
  content: '\e909';
}
.icon-house:before {
  content: '\e90a';
}
