.react-select,
.react-select-error {
  &__value-container,
  &__input-container {
    @include paragraph;
  }

  &__control {
    border: 1px solid #ced4da !important;
    text-transform: capitalize;
    min-height: 40px !important;
    color: #aaa;

    &:hover {
      border-color: $input-border !important;
    }
    &--is-focused,
    &--menu-is-open {
      border-color: $light-blue !important;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgb(52 143 226 / 25%) !important;
      &:hover {
        border-color: #5db0ff !important;
      }
    }
  }
  &__indicator-separator {
    display: none;
  }

  &__clear-indicator {
    svg {
      height: 14px !important;
      width: 14px !important;
      cursor: pointer;
      &:hover {
        color: $error;
      }
    }
  }

  &__dropdown-indicator {
    visibility: hidden;
    width: 0;
    height: 0;
    cursor: pointer;
    &::before {
      visibility: visible;
      font-family: 'icons';
      content: '\e90b';
      position: relative;
      top: -12px;
      left: -12px;
    }
  }

  &__menu,
  &__menu-list {
    height: fit-content !important;
    max-height: 180px !important;
    @include paragraph;
    text-transform: capitalize;
    z-index: 2 !important;
  }

  &__option {
    &--is-selected {
      background: $disabled !important;
      color: $dark-gray;
    }
  }

  &__input {
    font-weight: 400;

    &-container {
      cursor: text;
    }
  }

  &__multi-value {
    background-color: rgba(0, 126, 255, 0.08) !important;
    border: 1px solid rgba(0, 126, 255, 0.24);

    &__remove:hover {
      background-color: #b2d4ff !important;
      color: $dark-gray !important;
    }

    &__label {
      overflow: visible !important;
      white-space: normal !important;
      text-overflow: unset !important;
    }
  }
}

.react-select-error {
  &__control {
    border: 1px solid $error !important;
  }

  &__placeholder {
    color: #aaa;
    overflow: hidden;
    white-space: nowrap;
  }
}
