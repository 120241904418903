.tos {
  &__container {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    @include white-box;
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    @include respond(phone) {
      max-height: calc(100vh - 200px);
    }
  }

  &__comment {
    text-align: center;
    margin: 0 auto 40px;
    font-weight: bold;
  }
}
