.card {
  &__container {
    display: flex;
    align-items: center;
    padding: 20px 15px;
    flex-grow: 1;
    width: 100%;
    cursor: pointer;
    @include white-box;
    @include scale-transition;

    &--half {
      width: 50%;
      min-width: 340px;
    }
    @include respond(phone) {
      flex-wrap: wrap;
    }
  }
  &__column {
    margin-left: 20px;
    &--first {
      min-width: 190px;
      display: flex;
      align-items: center;
      @include respond(phone) {
        min-width: 110px;
        margin: 6px 0;
      }
      @include respond(tablet) {
        min-width: 180px;
      }
    }
    &--second {
      display: block;
      flex-grow: 1;
      padding-right: 10px;
      @include respond(phone) {
        margin: 6px 0;
      }
    }
  }
  &__country {
    line-height: 1;
    margin: 0 6px;
    @include paragraph;
  }
  &__btn {
    display: flex;
    justify-content: center;
    @include btn-clickable;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    small {
      line-height: 25px;
      @include btn-clickable;
    }

    &--fit {
      padding: 0 10px;
      width: fit-content;
      border: none;

      &:hover {
        background: $blue-active;
      }
    }

    &--w112 {
      padding: 0;
      width: 112px;
      min-width: 112px;
      @include btn-clickable;

      @include respond(phone) {
        margin: 6px 0;
      }
    }
  }

  &__name {
    text-decoration: none;
    color: $dark-gray;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 600;
    line-height: 1;
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: $dark-gray;
    }
  }
  &__icon {
    &--user {
      font-size: 70px;
      margin-bottom: 12px;
    }
    &--contact {
      color: $blue;
      margin-right: 6px;
    }
  }
  &__contact {
    color: $blue;
    @include paragraph;
    text-decoration: none;
    line-height: 21px;
  }

  &__item {
    list-style: none;
    @include paragraph;
    color: $dark-gray;
    margin: 0;
  }

  &__flag {
    width: 25px;
    min-width: 25px;
    &--small {
      width: 20px;
      min-width: 20px;
    }
  }

  &__roles {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__language {
    &::before {
      content: '\2022';
      color: $black;
      margin-right: 10px;
    }
  }
}
