.slider {
  &__container {
    width: 100%;
    margin-bottom: 50px;
  }
}

.slick-dots {
  bottom: -35px;
  li {
    margin: 3px !important;
    button:before {
      font-size: 10px;
    }
  }
}

.slick-prev,
.slick-next {
  top: unset;
  bottom: -42px;
  z-index: 10;
}

.slick-prev {
  left: calc(50% - 90px);
}
.slick-next {
  right: calc(50% - 90px);
}

.slick-prev:before,
.slick-next:before {
  color: $disabled;
  opacity: 1;
  font-family: 'icons';
}

.slick-prev:before {
  content: '\e9be';
}

.slick-next:before {
  content: '\e9bf';
}
