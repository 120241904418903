.resources {
  &__container {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    @include respond(phone) {
      padding: 0;
    }
  }

  &__tiles {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    @include respond(phone) {
      justify-content: center;
    }
  }

  &__title {
    @include main-heading;
  }

  &__help {
    font-weight: bold;
    margin-bottom: 10px;
  }
}
