.downloads {
  &__title {
    @include main-heading;
  }
  &__tiles {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    @include respond(phone) {
      justify-content: center;
    }
  }
}
