.collapsible {
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: $blue;
    border-radius: 4px 4px 2px 2px;
    text-align: center;
    color: $white;
    cursor: pointer;
  }

  &__text {
    margin: 0;
    color: $white;
    font-weight: 600;
    font-size: 16px;
  }

  &__icon {
    font-size: 18px;
    color: $white;
  }

  &__filters {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    background: $white;
    margin-bottom: 20px;
    border-radius: 0 0 4px 4px;
    transition: height 0.2s linear 0s;
    height: 118px;

    @include respond(phone) {
      height: 200px;
    }

    &--close {
      height: 0px;
      overflow: hidden;
    }
  }

  &__inputs {
    margin-top: 6px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    @include respond(phone) {
      flex-direction: column;
      gap: 10px;
    }
    .react-select-container {
      flex-grow: 1;
      margin-right: 30px;
      @include respond(phone) {
        margin-right: 0;
      }
    }
  }
}

/* height: 0px; transition: height 400ms linear 0s; overflow: hidden;

height: auto; transition: height 400ms linear 0s; overflow: visible; */
