.admin {
  &__tabs {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }

  &__tab {
    @include blue-btn;
    width: 150px;
    height: 40px;
    font-weight: 400;

    &--active {
      background: $blue-active;
    }

    &-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      @include white-box;
      padding: 20px;
      @include respond(tablet) {
        width: 100%;
      }
    }
  }

  &__staff-filters {
    background: $white;
    border-radius: 0 0 4px 4px;
  }

  &__titles {
    display: flex;
  }

  &__form {
    display: flex;
    flex-direction: column;
    padding: 0 0 10px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  &__comment {
    @include paragraph;
  }

  &__btn-container {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    gap: 15px;
    @include respond(phone) {
      flex-direction: column;
    }
  }

  &__btn-disabled {
    @include disabled-btn;
    padding: 0 15px;
    height: 40px;
  }

  &__btn {
    @include blue-btn;
    height: 40px;
    padding: 0 15px;
  }

  &__inputs {
    label {
      @include paragraph;
      margin: 15px 0 6px;
    }

    textarea {
      height: 150px;
      @include paragraph;
      font-weight: 400;
    }
  }

  &__about {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }

  &__column {
    width: 48%;
    display: flex;
    flex-direction: column;
    @include respond(phone) {
      width: 100%;
    }
  }

  &__columns {
    display: flex;
    justify-content: space-between;
    @include respond(phone) {
      flex-direction: column;
    }
  }

  &__icon {
    &--file {
      font-size: 20px;
      color: $disabled;
      margin-right: 10px;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
  }

  &__subtitle {
    @include subheading-small;
    margin: 20px 0 15px;
  }

  &__checklist {
    columns: 2;
    @include respond(tablet) {
      columns: 2;
    }
    @include respond(phone) {
      columns: 1;
    }

    .capabilities__block {
      break-inside: avoid;
    }
  }

  &__category {
    @include paragraph;
    font-weight: 600;
  }

  &__office {
    display: flex;
    flex-direction: column;
  }

  &__staff {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  &__flash {
    width: 100%;

    @include respond(tablet) {
      width: 100%;
    }
  }
}
