.modal {
  &__container {
    position: relative;
    width: 100vw;
    height: 100vh;
  }

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(28, 28, 28, 0.19);
    backdrop-filter: blur(6px);
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
    z-index: 100;
  }

  &__align {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  &__content {
    width: 800px;
    height: 500px;
    border-radius: 20px;
    background: transparent;
    color: #000;
    margin: 0rem 4rem;
    display: flex;
    align-items: center;
    @include respond(tablet) {
      margin: 0rem 1rem;
      width: 100%;
    }

    @include respond(phone) {
      background: transparent;
      height: auto;
    }
  }

  &__spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__spinner {
    animation: spin 2s linear infinite;
    font-size: 40px;
    color: #1b6aae;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
