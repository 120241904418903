.form {
  &__checkbox {
    display: flex;
    gap: 10px;
    min-height: 24px;
    margin-bottom: 6px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__checkbox-input {
    position: relative;
    top: 5px;
  }

  &__input {
    &--error,
    &--error:focus {
      border: 1px solid $error;
      box-shadow: none;
    }
  }
}
