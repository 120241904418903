:root {
  --toastify-color-light: #ffffff;
  --toastify-color-dark: #000000;
  --toastify-color-info: #0075c9;
  --toastify-color-success: #008000;
  --toastify-color-warning: #f7a909;
  --toastify-color-error: #e1251b;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
}

.Toastify__close-button {
  position: relative;
  top: -6px;
}

.Toastify__toast-body {
  margin: 0;
}

.toast {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include white-box;
    padding: 20px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  }

  &__actions {
    display: flex;
    gap: 30px;
  }

  &__action {
    min-width: 100px;
    &--accept {
      @include blue-btn;
    }

    &--decline {
      @include disabled-btn;
    }
  }

  &__text {
    text-align: center;
  }

  &__show {
    display: block;
    height: 140px;

    @include respond(phone) {
      height: 190px;
    }
  }

  &__hide {
    display: none;
  }
}
