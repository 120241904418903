.options {
  &__container {
    background-color: #fff;
    box-shadow: var(--shadow);
    position: absolute;
    top: 16px;
    right: 20px;
    width: 230px;
    z-index: 10;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    padding: 10px 5px;
  }

  &__option {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 4px 10px;
    color: $gray;

    &:hover {
      color: $orange;
    }
    i {
      color: $gray;
    }
  }
}
