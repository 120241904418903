.socials {
  &__widget {
    height: 100%;
    overflow: hidden;
    @include shadow-on-hover;
  }

  &__inner {
    height: fit-content;
    display: flex;
    padding: 10px 10px 0;
    gap: 20px;
    @include respond(phone) {
      height: fit-content;
    }
  }

  &__icon {
    margin-right: 10px;

    &--camera {
      position: absolute;
      top: 8px;
      right: 10px;
      font-size: 20px;
      color: $dark-gray;
    }
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    background: $white;
    padding: 20px;
    height: fit-content;
    max-width: 1100px;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 320px;
    height: 180px;
    background: $light-gray;
    position: relative;
    top: 30px;
    overflow: hidden;
    margin: 0 auto;
    max-width: 100%;

    &-container {
      margin: 0 auto;
      max-width: 100%;
    }
  }

  &__input {
    @include paragraph;
    font-weight: 400;
    max-width: 100%;

    &--img {
      display: none;
    }

    &--textarea {
      height: 260px;
    }

    &--error {
      border: 1px solid $error;
    }
  }

  &__error {
    font-size: 12px;
    font-weight: 600;
    color: $error;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 20px;
    max-width: 100%;
  }

  &__field {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }

  &__btn {
    width: 120px;

    &--disabled,
    &--cancel,
    &--photos {
      @include disabled-btn;
    }

    &--disabled {
      cursor: not-allowed;
    }

    &--active {
      @include blue-btn;
    }

    &--photos {
      width: 100%;
      font-weight: 400;
      margin-top: 6px;

      @include respond(phone) {
        padding: 10px;
        height: unset;
      }
    }
  }

  &__flash {
    display: block;
    width: 100%;
  }

  &__comment {
    @include paragraph;
    margin: 0;
    text-align: center;

    &-container {
      margin: 0 auto;
      width: 100%;
    }
    &--bold {
      @include subheading-small;
    }
  }

  &__skeletons {
    display: flex;
    gap: 20px;
  }

  &__click {
    font-weight: 600;
    font-size: 20px;
    margin: 0;
    text-align: center;
    position: absolute;
  }

  &__crop {
    display: flex;
    flex-direction: column;
    margin: 50px auto 0;
    width: 100%;
    text-align: center;
  }

  &__img {
    width: 100%;
  }
}
