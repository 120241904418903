.cookies {
  &__container {
    background: #ffffff;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 4px;
  }

  &__actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }

  &__action {
    min-width: 100px;
    &--accept {
      @include blue-btn;
    }

    &--decline {
      @include disabled-btn;
    }
  }
}
