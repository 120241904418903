.results {
  &__container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 24px;
    padding: 20px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__title {
    text-align: center;
  }
  
  &__icon {
    color: #d0d7df;
    font-size: 40px;
    padding: 28px;

    &-container {
      background-color: #e5e8ec;
      border-radius: 50%;
      margin-bottom: 20px;
      width: 90px;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__help {
    text-align: center;
  }
}
