.desks {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__header {
    width: 100%;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    padding: 20px 15px 10px;
    line-height: 1.2;
    @include respond(phone) {
      display: none;
    }

    p {
      margin: 0;
      font-weight: 600;
    }
  }

  &__card {
    display: flex;
    align-items: flex-start;
    padding: 20px 15px;
    flex-grow: 1;
    width: 100%;
    @include white-box;

    @include respond(phone) {
      flex-direction: column;
      gap: 10px;
    }
  }

  &__col-1 {
    width: 22%;
    gap: 6px;
    @include respond(phone) {
      width: 100%;
    }
  }

  &__col-2 {
    width: 38%;
  }
  &__col-3 {
    width: 40%;
  }

  &__col-2,
  &__col-3 {
    display: flex;
    flex-direction: column;
    @include respond(phone) {
      width: 100%;
    }
  }

  &__language {
    margin: 0;
    font-weight: 600;
    text-transform: capitalize;
  }

  &__firm {
    margin: 0 0 0 26px;
  }
}
