.offices {
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    row-gap: 40px;
    column-gap: 20px;
    @include respond(phone) {
      row-gap: 0;
    }
  }
  &__head {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__office {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 20px 0;
    width: calc(30% - 10px);
    @include respond(tablet) {
      width: calc(50% - 20px);
    }
    @include respond(phone) {
      width: 100%;
    }
  }

  &__locations {
    padding: 20px 0 0;
    margin: 10px 0 0;
  }

  &__title {
    font-size: 20px;
  }
}
