.list {
  &__container {
    width: 30%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    height: fit-content;

    @include respond(tablet) {
      width: 100%;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    height: 56px;
  }

  &__icon {
    color: $blue;
    font-size: 30px;
    margin-left: 6px;
  }

  &__card {
    width: 100%;

    @include respond(tablet) {
      width: calc(50% - 10px);
    }

    @include respond(phone) {
      width: 100%;
    }
  }
}
