.help {
  &__container {
    display: flex;
  }
  &__tooltip {
    font-size: 16px;
    color: $orange;
    margin: 3px 6px 0 0;
  }
  &__text {
    @include paragraph;
  }
  &__link {
    @include paragraph;
    color: $blue;
    font-weight: 600;
  }
}
