.breadcrumb {
  &__container {
    display: flex;
    margin: 0 0 10px;
  }

  &__text {
    margin: 0 6px 0 0;
    text-transform: capitalize;
    font-size: 16px;
  }
}
