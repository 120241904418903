.articles {
  &__container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    position: relative;
    cursor: pointer;
    @include white-box;
    @include shadow-on-hover;

    max-height: 428px;

    @include respond(phone) {
      max-height: 514px;
    }

    &:hover {
      i {
        color: $blue;
        font-size: 18px;
      }

      i.articles__thumbtack {
        color: $disabled;
      }
    }
  }

  &__header {
    padding: 0;
  }

  &__img {
    width: 100%;
    @include scale-transition;
    transform-origin: bottom center;
  }

  &__title {
    @include subheading;
    padding: 10px 10px 12px;
    max-height: 70px;
    height: fit-content;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: $dark-gray;
    @media screen and (min-width: 47.6em) and (max-width: 1389px) {
      font-size: 22px;
      max-height: 58px;
    }
  }

  &__body {
    padding-top: 0;
    flex-grow: 1;
    display: flex;
  }

  &__text {
    margin: 12px 0 30px;
    padding: 0 10px;
    color: $dark-gray;
    min-height: 20px;

    @include paragraph;
  }

  &__btn {
    @include blue-btn;
    width: 90px;
    margin: 10px;
    @include paragraph;
    height: 33px;
  }

  &__more-link {
    color: $blue;
    font-weight: 600;
  }

  &__comment {
    position: absolute;
    bottom: 3px;
    right: 12px;

    i {
      color: $disabled;
      transition: all 0.2s linear;
    }
  }

  &__thumbtack {
    position: absolute;
    bottom: 8px;
    left: 10px;
    font-size: 16px;
    z-index: 1;
    transform: rotate(45deg);
    color: $disabled;
    transition: all 0.2s linear;
  }
}
