.capabilities {
  &__container {
    @include white-box;
    padding: 20px;
  }

  &__inner {
    -webkit-columns: 2;
    columns: 2;
    @include respond(phone) {
      columns: 1;
    }

    .capabilities__block {
      break-inside: avoid;
    }
  }

  &__subtitle {
    @include category-title;
  }

  &__list {
    margin-bottom: 10px;
  }

  &__block {
    display: inline-block;
    width: 100%;
    margin-bottom: 6px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
}
