.video {
  &__container {
    @include white-box;
    width: 100%;
    padding: 20px;
    max-width: 800px;
    margin: 0;

    iframe {
      width: 100%;
      height: 450px;
      border-radius: 4px;
      @include respond(phone) {
        height: 300px;
      }
    }
  }

  &__title {
    @include main-heading;
    text-transform: capitalize;
    margin: 10px 0;
  }

  &__description {
    width: 100%;
    max-width: 800px;
    margin: 30px 0 0;
    text-align: left;
  }

  &__default {
    width: 100%;
  }
}
