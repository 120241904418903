.committees {
  &__container {
    padding: 16px 20px;
    margin-bottom: 30px;
    border-bottom: $separator-light;

    &:last-child {
      border: none;
    }
  }

  &__title {
    @include main-heading;
    margin: 20px 0;
  }

  &__subtitle {
    font-size: 22px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &--active {
      border-left: 6px solid #f5a800;
    }
  }

  &__users {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }

  &__user {
    background-color: $white;
    border-radius: 4px;
    width: 400px;
    @include scale-transition;
    @include shadow-on-hover;
  }

  &__accordion {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }
}
