.resource {
  &__container {
    width: calc(33% - 20px);
    min-width: 250px;
    background: $white;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    @include shadow-on-hover;

    @include respond(big-desktop) {
      width: calc(25% - 20px);
    }
    @media screen and (max-width: 599px) {
      width: 100%;
    }
  }

  &__tags {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    line-height: 1;
    font-size: 14px;
    color: $gray-header;
  }

  &__body {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    @include paragraph;
    color: $dark-gray;
    height: 100%;
    min-height: 212px;
    position: relative;
  }

  &__date {
    margin: 0;
  }

  &__title {
    font-size: 20px;
    margin: 0;
    color: $dark-gray;
  }

  &__icon {
    font-size: 48px;
    color: $blue;
  }

  &__svg {
    width: 36px;
  }

  &__btn {
    all: unset;
    @include blue-btn;
    height: 30px;
    width: 120px;
    @include paragraph;
    @include btn-clickable;
  }

  &__description {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 58px;
    line-height: 1.2;
    transition: all 0.5s linear;
    cursor: pointer;
    color: $dark-gray;

    &:hover {
      overflow: visible;
      max-height: 500px;
      text-overflow: initial;
      white-space: wrap;
      display: flex;
    }

    &:hover .resource__body {
      max-height: unset;
    }
  }

  &__action {
    display: flex;
    align-items: flex-end;
    gap: 18px;
    position: absolute;
    bottom: 15px;

    i {
      font-size: 48px;
    }

    &-placeholder {
      height: 48px;
    }
  }

  &__separator {
    width: 100%;
    height: 1px;
    background: $orange;
  }

  &__thumbtack {
    position: absolute;
    top: 10px;
    right: 14px;
    font-size: 16px;
    transform: rotate(45deg);
    color: $disabled;
  }

  &__video {
    width: 100%;
  }

  &__details {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
}
