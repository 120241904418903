.events {
  &__container {
    display: flex;
    flex-direction: column;

    @include respond(phone) {
      padding: 0;
    }
  }

  &__title {
    @include main-heading;
  }

  &__comment {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__no-events {
    @include subheading-small;
    margin: 0;
  }

  &__icon {
    color: $dark-gray;
    font-size: 16px;
  }

  &__custom-page {
    display: flex;
    flex-direction: column;
    gap: 40px;

    p {
      @include paragraph;
    }
  }
}
