.filter {
  &__container {
    display: flex;
    padding: 0 0 20px;
    @include paragraph;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    align-items: start;
    padding-right: 27px;
    @include respond(big-desktop) {
      padding-right: 20px;
    }
    @include respond(tablet) {
      padding-right: 0;
    }
  }

  &__label {
    font-weight: 600;
  }

  &__filter {
    display: flex;
    gap: 10px;
    align-items: center;

    @include respond(phone) {
      width: 100%;
      min-width: unset;
    }

    &--long {
      width: 40%;
      margin-right: 0;
      min-width: 430px;
      @include respond(phone) {
        width: 100%;
        min-width: unset;
      }
    }

    &--date {
      @include paragraph;
      font-weight: 400;
      cursor: pointer;
      height: 38px;
    }
  }

  &__field {
    flex: 1;
    position: relative;
    max-width: 300px;
    min-width: 170px;
    width: 23%;

    &--search {
      max-width: none;
    }

    @include respond(phone) {
      max-width: unset;
    }

    .form-select {
      cursor: pointer;
      @include paragraph;
      font-weight: 400;
      text-transform: capitalize;
    }

    .react-select__dropdown-indicator {
      opacity: 0;
      z-index: 1000;
    }
    .react-select__clear-indicator {
      margin-right: 5px;
      cursor: pointer;
    }
  }

  &__options {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: $white;
    width: 100%;
    z-index: 10;
    border-radius: 0 0 4px 4px;
  }
  &__label {
    min-width: 56px;
    &--long {
      min-width: 110px;
    }
  }
  &__option {
    text-transform: capitalize;
    @include paragraph;
    font-weight: 400;
    color: $dark-gray;
    &:hover {
      background: #f5faff;
    }
  }
  &__text-input {
    @include paragraph;
    font-weight: 400;
    color: $dark-gray;
  }

  &__search {
    @include input;
    padding-right: 30px;
  }

  &__icon {
    position: absolute;
    right: 12px;
    top: 11px;
    color: $disabled;
  }

  &__btns {
    display: flex;
    gap: 10px;
  }

  &__btn {
    all: unset;
    border-radius: 4px;
    text-decoration: none;
    background: $input-border;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    width: 50px;
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 10px;
    text-align: center;
    @include btn-clickable;

    &--active {
      background: $disabled;
    }
  }
}

.filters {
  &__container {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 0;
    margin-bottom: 10px;
    @include respond(phone) {
      flex-direction: column;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    width: 30%;
    @include respond(phone) {
      width: 100%;
    }

    &--mt {
      margin-top: 34px;
      @include respond(phone) {
        margin-top: 0;
      }
    }
  }

  &__input {
    margin: 5px 0 20px;
    height: 40px;
    @include paragraph;
    font-weight: 400;
    color: #aaa;
  }

  &__select {
    margin: 6px 0 5px;
  }
  &__btn {
    display: flex;
    align-items: center;
    height: 30px;
    @include btn-clickable;

    small {
      line-height: 25px;
      font-size: 16px;
      color: $white;
      font-weight: 300;
      padding: 0 6px;
    }

    &-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @include paragraph;
      margin-bottom: 10px;

      &--mt {
        margin-top: 75px;
        @include respond(phone) {
          margin: 0 0 20px;
        }
      }

      span {
        margin-bottom: 5px;
        font-size: 16px;
      }
    }
  }

  &__label {
    font-weight: 600;
    margin: 10px 0 0;
    font-size: 16px;
  }
}
