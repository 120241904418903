.registration {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;
    position: relative;
    top: -35px;
    left: 120px;
    margin-top: 50px;

    @include respond(phone) {
      position: relative;
      left: 0;
      top: -50px;
      margin: 50px 20px;
    }
  }

  &__logo {
    width: 200px;
    margin-bottom: 55px;
  }

  &__title {
    @include subheading;
    margin: 0 30px 20px;
    text-align: center;
  }

  &__widget {
    width: 90%;
    max-width: 900px;
    background: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 30px;
    @include respond(tablet) {
      width: 100%;
    }
    @include respond(big-desktop) {
      max-width: 1024px;
    }
  }

  &__tabs {
    width: 100%;
  }

  &__steps {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__step {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 4px;
    @include respond(phone) {
      width: 100%;
      display: none;
    }

    &--active {
      border-bottom: 4px solid $orange;
      padding-bottom: 0;
      @include respond(phone) {
        display: block;
      }
    }
  }

  &__btn {
    width: 90px;
    @include respond(phone) {
      width: 100%;
    }
    &--enabled {
      @include blue-btn;
    }
    &--disabled {
      @include disabled-btn;
      cursor: not-allowed;
    }
    &--enabled,
    &--disabled {
      @include respond(phone) {
        width: 100%;
      }
    }

    &--previous {
      width: 90px;
      margin: 0 30px;
      @include respond(phone) {
        margin: 0;
        width: 100%;
        margin: 20px 0;
      }
    }

    &--complete {
      padding: 0 12px;
    }
  }

  &__link {
    @include paragraph;
    color: $blue;
    font-weight: 600;
  }

  &__error {
    display: flex;
    align-items: center;
    @include paragraph;
    color: $error;
    margin: 20px 0 0;
    span {
      color: $orange;
      margin-right: 10px;
    }
  }
}

.step {
  &__title,
  &__description {
    font-size: 16px;
    text-align: center;
  }

  &__title {
    font-weight: 600;
    margin: 0;
  }

  &__description {
    margin-bottom: 10px;
  }
}
