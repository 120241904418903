.widget {
  &__container {
    &--twitter {
      border-radius: 11px;
      width: 70%;
      margin: 0 auto;
    }
  }
  &__header {
    padding: 0.61875rem 0.9375rem;
    display: flex;
    align-items: center;
    background: $gray-header;
    color: $white;
    font-size: 16px;
    height: 48px;
  }

  &__heading {
    @include gray-heading;
  }

  &__col {
    width: 33%;
    position: relative;
  }

  &__img {
    width: 100%;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    padding: 10px;
    height: 60px;
    margin-bottom: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__btns {
    display: flex;
    gap: 20px;
    margin: 20px auto;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1.5px solid $orange;
    width: 100%;

    &--contacts {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 0 20px;
    }
  }

  &__btn {
    all: unset;
    @include blue-btn;
    @include paragraph;
    height: 33px;
    width: 75px;

    &--social {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &--out {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 20px auto 0;
      height: 33px;
      width: 100%;
      font-size: 16px;
      font-weight: 600;
      border-bottom: 1.5px solid $orange;
      color: $blue;

      i {
        margin-left: 6px;
      }

      &-group {
        margin: 0;
        border: none;
        width: fit-content;
      }
    }

    &--contacts {
      width: 100%;
      font-size: 16px;
    }
  }

  &__btn,
  &__btn--social {
    @include btn-clickable;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 45px);
    margin: 0;
  }

  &__date {
    font-size: 16px;
    font-weight: bold;
    text-transform: none;
    letter-spacing: unset;
    color: $dark-gray;
  }

  &__description {
    border: none;
    background: $white;
    padding: 0;
    text-align: start;
    @include widget-description;
    @include paragraph;

    &--big {
      font-size: 18px;
    }
    &--bold {
      font-weight: 600;
    }

    &-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    height: 58px;
    padding: $widget-padding;
    border-bottom: $widget-border;
    gap: 10px;

    &--nb {
      border: none;
    }

    &--no-height {
      height: unset;
      padding: 4.3px 15px;
    }

    &-container {
      padding: 0 16px;
    }

    &-description {
      color: $black;
      cursor: pointer;
      line-height: 1.2;
      text-decoration: none;
    }
  }

  &__footer {
    padding: $widget-padding;
    flex: 1;
    min-height: 53px;
  }

  &__no-items {
    margin-bottom: 232px;
    display: flex;
    align-items: center;
    height: 58px;
    padding: 0.61875rem 0.9375rem;
    gap: 10px;
  }

  &__thumbnail {
    &--small {
      width: 30px;
    }

    &--medium {
      width: 100px;
    }
  }

  &__key-contacts {
    margin: 0;
    min-height: 343px;
    padding: 30px 15px 10px;
  }
}
