.header {
  &__container {
    padding-right: 10px;
    max-width: 1920px;
    margin: 0 auto;
    box-shadow: 0 0.1rem 1rem rgb(0 0 0 / 10%);
  }

  &__logo {
    width: 120px;
  }

  &__inner-wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  &__picture {
    width: 30px;
    margin: 0 6px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
  }

  &__no-picture {
    font-size: 28px !important;
    color: $disabled-active;
  }

  &__actions {
    display: flex;
    align-items: center;

    .caret {
      color: $black;
    }
  }

  &__user {
    display: flex;
    flex-direction: column;
    @include respond(phone) {
      display: none;
    }
  }

  &__name {
    @include paragraph;
    margin: 0 7px;
    color: $black;
  }

  &__role {
    font-size: 14px;
    margin: 0 7px;
    text-transform: capitalize;
    color: $disabled;
    line-height: 1;
  }

  &__action {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 6px;
  }

  &__link,
  &__icon {
    @include paragraph;
    margin: 0;
  }

  &__link {
    font-weight: 400;
  }

  &__icon {
    font-weight: 600;
  }

  &__dropdown {
    top: -3px;
  }
}

// Don't remove, it's a fix for the bootstrap template in production
.app-header .navbar-header {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  height: 60px;
}

.app-header-fixed {
  padding-top: 60px !important;

  @include respond(tablet) {
    padding-top: 110px !important;
  }

  @include respond(phone) {
    padding-top: 140px !important;
  }
}

.app-header .navbar-brand {
  min-width: 160px;
}
