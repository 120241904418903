.home {
  &__container {
    width: 100%;
    margin: 20px auto 0;
    padding-bottom: 60px;

    @include respond(phone) {
      margin-top: 0;
    }
  }

  &__title {
    font-weight: 600;
    @include main-heading;
    margin-bottom: 15px;
  }

  &__subtitle,
  &__subtitle-link,
  &__subtitle-separator {
    @include main-heading;

    &--social {
      font-size: 20px;
      font-weight: 600;
      color: $dark-gray;
    }
  }

  &__subtitle {
    color: $dark-gray;
    &-container {
      display: flex;
      align-items: center;
      gap: 12px;

      @include respond(phone) {
        flex-direction: column;
        gap: 0;
      }
    }
    &-link,
    &-link--social {
      color: $disabled;
      display: flex;
      align-items: center;
      gap: 6px;
    }
    &-separator,
    &-separator--social {
      color: $orange;
    }

    &-separator {
      @include respond(phone) {
        display: none;
      }
    }
  }

  &__widget {
    min-width: 250px;

    @include shadow-on-hover;

    @include respond(tablet) {
      width: 50%;
    }
    @include respond(phone) {
      width: 100%;
    }

    &--socials {
      width: 100%;
    }

    &--25 {
      width: calc(25% - 15px);
      @include respond(phone) {
        width: 100%;
      }
    }

    &--33 {
      width: calc(33% - 11px);
      @include respond(phone) {
        width: 100%;
      }
    }
  }

  &__skeletons {
    display: flex;
    gap: 20px;
    width: 100%;
  }
}

.twitter-timeline {
  height: 100% !important;
}
