.contact-card {
  &__container {
    display: flex;
    padding: 16px 20px;
    min-width: 275px;
    cursor: pointer;
    @media screen and (max-width: 457px) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__img {
    width: 60px;
    min-width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    @include scale-transition;

    img {
      object-fit: cover;
      border-radius: 50%;
      height: 60px;
      width: 60px;
    }
  }

  &__name,
  &__role {
    @include paragraph;
    margin: 0;
    color: $dark-gray;
    min-height: 10px;
    @media screen and (max-width: 457px) {
      text-align: center;
      margin: 0 4px;
    }
  }

  &__item {
    list-style: none;
    @include paragraph;
    color: $dark-gray;
    margin: 0;
    @media screen and (max-width: 457px) {
      text-align: center;
    }
  }

  &__name {
    font-weight: 600;
  }

  &__country {
    display: flex;
    align-items: center;
    margin-top: 6px;
    @media screen and (max-width: 457px) {
      text-align: center;
      justify-content: center;
    }
  }

  &__email {
    display: flex;
    align-items: center;
    gap: 6px;

    i {
      color: $blue;
    }
  }

  &__flag {
    width: 20px;
    margin-right: 6px;
    height: 26px;
  }
}
